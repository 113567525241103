<template>
    <div class="row">
        <div class="col-xl-12">
            <div id="panel-1" class="panel">
                <div class="panel-container show">
                    <div class="panel-content">
                        <el-row :gutter="10">
                          <el-col v-if="isHCKV" :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                            <el-select clearable v-model="form.branch_id"  filterable class="w-100 mt-3"
                                       placeholder="Chọn văn phòng để xem" @change="changeBranch">
                              <el-option
                                  v-for="item in branches"
                                  :key="item.id"
                                  :label="item.name_alias ? item.name_alias : item.name"
                                  placeholder="Chọn cơ sở"
                                  :value="item.id"
                              >
                                <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                              </el-option>
                            </el-select>
                          </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="5">
                                <el-date-picker class="w-100 mt-3"
                                                v-model="form.date"
                                                type="week"
                                                :format="view"
                                                :picker-options="pickerOptions"
                                                value-format="yyyy-MM-dd"
                                                @change="changeDate"
                                                placeholder="Chọn tuần">
                                </el-date-picker>
                            </el-col>
                        </el-row>
                        <div class="mb-5 mt-5" v-if="form.date" >
                            <h4>Lịch đăng kí từ ngày <b class="text-primary">{{ form.start }}</b> đến ngày <b class="text-primary">{{ form.end }}</b></h4>
                        </div>
                        <!-- datatable start -->
                        <el-empty v-if="!form.date" description="Bạn chưa chọn ngày"></el-empty>
                        <el-empty v-if="isHCKV && !form.branch_id" description="Bạn chưa văn phòng"></el-empty>
                        <el-row v-else v-loading="loading.loadingCalendar" class="table-responsive-lg">
                            <table class="table table-bordered m-0">
                                <thead>
                                <tr>
                                    <th class="action-date">
                                        <i @click="preDate()" class="el-icon-arrow-left"></i>
                                    </th>
                                    <th class="text-center" :class="{
                                        'past': item.day < current,
                                        'current-date': item.day === current
                                    }" v-for="(item, key) in table.columns" :key="key">
                                        <span class="text-capitalize">{{item.dayOfWeek }}</span> <br> {{ item.day }}
                                    </th>

                                    <th class="action-date">
                                        <i @click="nextDate()" class="el-icon-arrow-right"></i>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row" class="session attendance"> Buổi sáng</th>
                                    <td class="" :class="{
                                        'past': item.day < current,
                                        'current-date': item.day === current,
                                    }" v-for="(item, key) in table.columns" :key="key">
                                        <attendance-item
                                                :dataRegister="dataRegister"
                                                :day="item.day"
                                                session="morning"
                                                v-on:action-leader="changeLeader(item.day, 'morning')"
                                                v-on:action-attendance="attendance(item.day, 'morning')">
                                        </attendance-item>
                                    </td>
                                    <th scope="row" class="session attendance"> Buổi sáng</th>
                                </tr>
                                <tr>
                                    <th scope="row" class="session attendance top"> Buổi chiều</th>
                                    <td class="" :class="{
                                        'past': item.day < current,
                                        'current-date': item.day === current,
                                    }" v-for="(item, key) in table.columns"  :key="key">
                                        <attendance-item
                                                :dataRegister="dataRegister"
                                                :day="item.day"
                                                session="afternoon"
                                                v-on:action-leader="changeLeader(item.day, 'afternoon')"
                                                v-on:action-attendance="attendance(item.day, 'afternoon')">
                                        </attendance-item>
                                    </td>
                                    <th scope="row" class="session attendance top"> Buổi chiều</th>
                                </tr>
                                <tr>
                                    <th scope="row" class="session attendance top"> Buổi tối</th>
                                    <td class="" :class="{
                                        'past': item.day < current,
                                        'current-date': item.day === current
                                    }" v-for="(item, key) in table.columns" :key="key">
                                        <attendance-item
                                                :dataRegister="dataRegister"
                                                :day="item.day"
                                                session="evening"
                                                v-on:action-leader="changeLeader(item.day, 'evening')"
                                                v-on:action-attendance="attendance(item.day, 'evening')">
                                        </attendance-item>
                                    </td>
                                    <th scope="row" class="session attendance top"> Buổi tối</th>
                                </tr>
                                </tbody>
                            </table>
                        </el-row>
                        <!-- datatable end -->
                        <!--eslint-disable-->
                        <el-dialog :title="dialogForm.title" class="responsive w-30" :visible.sync="dialogFormVisible">
                            <el-form :model="formLeader">
                                <el-form-item label="Chọn loại tài khoản">
                                    <el-select :disabled="dialogForm.condition || !isRegister" @change="changeAccountType()" class="w-100" clearable filterable v-model="formLeader.account_type" placeholder="Chọn loại tài khoản">
                                        <el-option v-for="(item, key) in accountOption" :key="key" :label="item" :value="key"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="Chọn Leader cho ca làm">
                                    <el-select :disabled="dialogForm.condition || !isRegister" v-loading="loading.loadingSelect" class="w-100" clearable filterable v-model="formLeader.sale" placeholder="Chọn leader cho ca làm">
                                        <el-option v-for="(item, key) in saleOption" :key="key" :label="item.text" :value="item.id"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                            <span slot="footer" class="dialog-footer">
                            <el-button @click="dialogFormVisible = false">Hủy</el-button>
                            <el-button :loading="loading.btnLeader" v-if="!dialogForm.condition && isRegister" type="primary" @click="updateSelectLeader()">Xác nhận</el-button>
                          </span>
                        </el-dialog>

                        <!--eslint-disable-->
                        <el-dialog  :title="dialogForm.title" class="responsive" :visible.sync="dialogFormAttendance">
                            <h4 :loading="loading.loadingAttendance">Leader: <b class="text-danger">{{ formAttendance.leader }}</b></h4>
                            <h4>Danh sách đăng kí đi làm:</h4>
                            <el-row>
                                <el-row v-loading="loading.loadingAttendance" class="table-responsive-lg">
                                    <table class="table table-bordered m-0">
                                        <thead>
                                        <tr>
                                            <th> Họ và tên </th>
                                            <th class="text-center"> Đi làm ? </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(item) in formAttendance.registers">
                                            <td> {{ item.user.name || '' }} - <b>{{ item.user_id + 100000 }}</b> </td>
                                            <td class="text-center">
                                                <el-checkbox
                                                        v-model="modelAttendance" :disabled="!dialogForm.condition" class="checkbox-calendar"
                                                        :label="item.user_id"
                                                        :key="item.user_id">
                                                </el-checkbox>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </el-row>
                            </el-row>
                            <span slot="footer" class="dialog-footer">
                            <el-button @click="dialogFormAttendance = false">Hủy</el-button>
                            <el-button :loading="loading.btnAttendance" v-if="dialogForm.condition" type="primary" @click="updateAttendance()">Lưu</el-button>
                          </span>
                        </el-dialog>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import {
        REGISTER_TOTAL,
        GET_LEADER,
        SELECT_LEADER,
        TIME_KEEP,
        TIME_KEEP_ATTENDANCE
    } from "@/core/services/store/service.module";
    import {BRANCH_LIST} from "@/core/services/store/service.module";
    import { HANH_CHINH_KHU_VUC } from "@/core/config/accountTypeOption";
    import AttendanceItem from "@/views/pages/work/attendance-item";
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);
    import {mapGetters} from "vuex";

    export default {
        name: "EdutalkAttendance",
        components: {
            AttendanceItem,
        },
        data() {
            return {
                loading: {
                    loadingCalendar: false,
                    loadingSelect: false,
                    loadingAttendance: false,
                    btnLeader: false,
                    btnAttendance: false
                },
                pickerOptions: {
                    firstDayOfWeek: 1,
                },
                dialogFormVisible: false,
                dialogFormAttendance: false,
                form: {
                    date: null,
                    start: null,
                    end: null,
                    branch_id : null,
                },
                formLeader: {
                    account_type: null,
                    sale: null,
                },
                table: {
                    columns: [],
                },
                current: null,
                morning: null,
                afternoon: null,
                evening: null,
                view: null,
                transform: {
                    morning: 'Ca sáng',
                    afternoon: 'Ca chiều',
                    evening: 'Ca tối',
                },
                dataRegister: null,
                saleOption: null,
                accountOption : {
                    'sale leader': "Sales Leader",
                    'GĐVP': "Giám đốc văn phòng",
                },
                dialogForm: {
                    condition: null,
                    title: null
                },
                formAttendance: {
                    leader: null,
                    registers: null,
                    model: []
                },
                modelAttendance: [],
                branches: [],
                isHCKV : false,
                isRegister : true,
                HANH_CHINH_KHU_VUC,
            }
        },
      async created() {
         if (this.currentUser.profile.account_type.id === this.HANH_CHINH_KHU_VUC){
           this.isHCKV = true;
           await this.getBranches();
         }
        this.current = this.$moment().format('YYYY-MM-DD');
        this.form.date = this.$moment().format('YYYY-MM-DD');
        this.convertViewDate();
      },

      mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Chấm công", icon: 'far fa-calendar-alt'}
            ]);

        },

      computed: {
        ...mapGetters(['currentUser'])
      },

      methods: {
          isAbleToUpdateAttendance (dialogForm){
            return !dialogForm.condition ;
          },
          changeDate() {
              this.table.columns = [];
              this.convertViewDate();
          },
          changeBranch() {
              this.table.columns = [];
              this.convertViewDate();
          },
          convertViewDate() {
              let begin = this.$moment(this.form.date, 'YYYY-MM-DD').startOf('week');
              let end = this.$moment(this.form.date, 'YYYY-MM-DD').endOf('week');
              this.form.start = begin.format('YYYY-MM-DD');
              this.form.end = end.format('YYYY-MM-DD');
              this.view = (this.form.start + ' đến ' + this.form.end);
              this.table.columns.push({ 'dayOfWeek': begin.format('dddd'), 'day': begin.format('YYYY-MM-DD') });
              for (let i = 0; i < 6; i ++) {
                  let data = begin.add(1, 'days');
                  this.table.columns.push({ 'dayOfWeek': data.format('dddd'), 'day': data.format('YYYY-MM-DD') })
              }
              this.reloadModel();
          },
          nextDate() {
              this.loading.loadingCalendar = true;
              this.table.columns = [];
              let nextDate = this.$moment(this.form.date, 'YYYY-MM-DD').endOf('week').add(1, 'days');
              this.form.date = nextDate.format('YYYY-MM-DD');
              this.convertViewDate();

          },
          preDate() {
              this.loading.loadingCalendar = true;
              this.table.columns = [];
              let preDate = this.$moment(this.form.date, 'YYYY-MM-DD').startOf('week').subtract(1, 'days');
              this.form.date = preDate.format('YYYY-MM-DD');
              this.convertViewDate();
          },
          reloadModel: function () {
              let param = this.mergeParams();
              this.loading.loadingCalendar = true;
              this.$store.dispatch(REGISTER_TOTAL, param)
                  .then((response) => {
                      this.dataRegister = response.data;
                      this.loading.loadingCalendar = false;
                  });
          },
          mergeParams(customProperties) {
              let params = { };
              if (this.form.start) {
                  params = _.merge(params, {startDay: this.form.start})
              }
              if (this.form.end) {
                  params = _.merge(params, {endDay: this.form.end })
              }
              if (this.form.branch_id) {
                  params = _.merge(params, {branch_id: this.form.branch_id })
              }
              params = _.merge(params, customProperties);
              return params;
          },
          changeLeader(day, session) {
              this.dialogForm.title = `Chọn leader ( ${this.transform[session]}: ${day} ) `;
              this.dialogForm.condition = day < this.$moment().subtract(4, 'days').format('YYYY-MM-DD');
              this.dialogForm.date = day;
              this.dialogForm.session = session;
              this.dialogFormVisible = true;
              let sale = {};
              if (this.dataRegister[day][session]['leader'] > 0) {
                  sale = {
                      sale : this.dataRegister[day][session]['leader']
                  }
              }
              this.formLeader.account_type = null;
              this.fetchLeaderByAccount(sale);
          },
          changeAccountType() {
              this.formLeader.sale = null;
              this.fetchLeaderByAccount();
          },
          fetchLeaderByAccount(customProperties) {
              let params = {};
              if ( this.formLeader.account_type) {
                  params = _.merge(params, { account_type:  this.formLeader.account_type })
              }
              if ( this.form.branch_id) {
                  params = _.merge(params, { branch_id:  this.form.branch_id })
              }
              this.loading.loadingSelect = true;
              params = _.merge(params, customProperties);
              this.$store.dispatch(GET_LEADER, params)
                  .then((response) => {
                      let sales = [];
                      _.each(response.data, (item) => {
                          if (customProperties && !this.formLeader.account_type) {
                              this.formLeader.account_type = item.account_type;
                          }
                          sales.push({ id: item.id, text: item.text })
                      });
                      if (customProperties) {
                          this.formLeader.sale = customProperties.sale;
                      }
                      this.saleOption = sales;
                      this.loading.loadingSelect = false
                  });
          },
          updateSelectLeader() {
              let params = {
                  user_id : this.formLeader.sale,
                  session : this.dialogForm.session,
                  date : this.dialogForm.date,
                  branch_id : this.form.branch_id,
              };
              this.loading.btnLeader = true;
              this.$store.dispatch(SELECT_LEADER, params)
                  .then((response) => {
                      if (response.status === 500 || response.status === 422) {
                          this.dialogFormVisible = false;
                          this.$notify({
                              title: 'Warning',
                              message: 'Tạm thời chưa thể cập nhật. vui lòng thử lại sau',
                              type: 'warning'
                          });
                      } else  {
                          this.dataRegister[params.date][params.session]['leader'] = params.user_id;
                          this.dialogFormVisible = false;
                          this.loading.btnLeader = false;
                          this.$message({
                              type: 'success',
                              message: 'Cập nhật leader thành công',
                              showClose: true
                          });
                      }
                  });
          },
          attendance(day, session) {
              this.formAttendance.leader = '';
              this.dialogForm.title = `Chấm công ( ${this.transform[session]}: ${day} ) `;
              this.dialogForm.condition = day > this.$moment().subtract(3, 'days').format('YYYY-MM-DD') && day <= this.$moment().format('YYYY-MM-DD');
              this.dialogForm.date = day;
              this.dialogForm.session = session;
              this.dialogFormAttendance = true;
              this.fetchTimeKeep();
          },
          fetchTimeKeep() {
              let params = {
                  session_of_day : this.dialogForm.session,
                  date : this.dialogForm.date,
                  branch_id : this.form.branch_id,
              };
              this.modelAttendance= [];
              let index = `${params.session_of_day}_attendance`;
              this.loading.loadingAttendance = true;
              this.$store.dispatch(TIME_KEEP, params)
                  .then((response) => {
                      this.formAttendance = response.data;
                      _.each(response.data.registers, (item) => {
                          if (item[index] === 1) {
                              this.modelAttendance.push(item.user_id)
                          }
                      });
                      this.loading.loadingAttendance = false;
                  });
          },
          updateAttendance() {
              let params = {
                  session_of_day : this.dialogForm.session,
                  date : this.dialogForm.date,
                  time_keep: this.modelAttendance,
                  branch_id: this.form.branch_id,
              };
              this.loading.btnAttendance = true;
              this.$store.dispatch(TIME_KEEP_ATTENDANCE, params)
                  .then((response) => {
                      if (response.status === 500 || response.status === 422) {
                          this.dialogFormAttendance = false;
                          this.$notify({
                              title: 'Warning',
                              message: 'Tạm thời chưa thể điểm danh. vui lòng thử lại sau',
                              type: 'warning'
                          });
                      } else  {
                          let attendance = `${params.session_of_day}_attendance`;
                          this.dataRegister[params.date][attendance] = this.modelAttendance.length ?? 0;
                          this.dialogFormAttendance = false;
                          this.loading.btnAttendance = false;
                          this.$message({
                              type: 'success',
                              message: 'Điểm danh thành công',
                              showClose: true
                          });
                      }
                  });
          },
          async getBranches() {
            await this.$store.dispatch(BRANCH_LIST)
                .then((response) => {
                  if (response.status === 422) {
                    this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                  } else {
                    this.branches = response.data;
                    this.form.branch_id = response.data.length > 0 ? response.data[0].id : 0;
                    }
                })
          },
      },

    };

</script>
