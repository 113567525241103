<template>
    <div class="box-regiter-work" v-if="checkShowView()">
        <div v-if="dataRegister && dataRegister[day] && dataRegister[day][session]['amount'] > 0 ">
            <h5>Trưởng nhóm: <b> {{ dataRegister[day][session]['leader'] ? 1 : 0 }}</b></h5>
            <h5>Người đăng kí: <b>{{ dataRegister[day][session]['amount'] || 0 }}</b></h5>
            <el-row>
                <el-button size="mini"
                           v-if="justPlurality()"
                           :type="checkVisible() ? 'primary' : 'info'"
                           class="mt-2 pt-2 pl-1 pr-1 mr-2"
                           @click="selectLeader">Chọn Leader
                    <i v-if="checkVisible()" class="el-icon-check" ></i>
                </el-button>
                <el-button size="mini"
                           :type="checkVisible(true) ? 'primary' : 'info'"
                           class="mt-2 pt-2 pl-1 pr-1 mr-2"
                           @click="actionAttendance">Chấm công
                    <i v-if="checkVisible(true)" class="el-icon-check" ></i>
                </el-button>
            </el-row>
        </div>
    </div>
</template>

<script>
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);
    import {mapGetters} from "vuex";
    import { HANH_CHINH_KHU_VUC } from "@/core/config/accountTypeOption";

    export default {
        name: "AttendanceItem",
        props: {
            dataRegister: [Array, Object],
            day: String,
            session: String,
        },
        data() {
            return {
              HANH_CHINH_KHU_VUC
            }
        },
      mounted() {
      },
      methods: {
            justPlurality(index = 'TPOnline') {
                return _.findIndex(this.currentUser.user_positions, function (item){
                  return item.position === index || item.position === 'TPOL';
                }) > -1 || this.currentUser.profile.account_type.id === this.HANH_CHINH_KHU_VUC;
            },
            checkShowView() {
                let accountTypeName = this.currentUser?.profile?.account_type?.name ?? this.currentUser?.account_type?.name ?? "";
                return ['GĐVP'].includes(accountTypeName)
                    || this.currentUser.profile.account_type.id === this.HANH_CHINH_KHU_VUC
                    || this.currentUser.user_positions.filter(d=>['TPOnline','TPOL','TPNS'].includes(d.position) ).length >0
                    || ( this.dataRegister
                        && this.dataRegister[this.day]
                        && this.dataRegister[this.day][this.session]['amount'] > 0
                        && this.currentUser.id === this.dataRegister[this.day][this.session]['leader']);
            },
            checkVisible (attendance = false) {
              let strSession = `${this.session}_attendance`;
              if (this.dataRegister[this.day]) {
                    if (attendance) {
                        return this.dataRegister[this.day][strSession] > 0
                    } else {
                        return this.dataRegister[this.day][this.session]['leader'] > 0
                    }
                }
                return false
            },
            selectLeader () {
                this.$emit('action-leader')
            },
            actionAttendance () {
                this.$emit('action-attendance')
            }
        },
        computed: {
            ...mapGetters(['currentUser'])
        }
    };

</script>
